<template>
  <a :href="`#${targetId}`" @click="onNavClick($event, targetId)">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'InternalLink',

  props: {
    targetId: {
      type: String,
      default: '',
    },

    clickEvent: {
      type: Function,
      default: null,
    },
  },

  methods: {
    onNavClick(e, slug) {
      if (this.clickEvent) {
        this.clickEvent()
      }

      const $el = document.getElementById(slug)

      if ($el) {
        e.preventDefault()
        $el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    },
  },
}
</script>
